.master-password-card {
  width: 50%;
  border-radius: 0.5rem;
}
.master-password-card div:first-child {
  color: #555555;
  letter-spacing: 1px;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: 'Poppins';
}
.create-password-btn {
  width: 7rem;
}
.master-password-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.master-password-buttons button {
  width: 6rem;
}
.master-password-buttons .cancel-button {
  background-color: transparent;
  color: #fff;
  margin-left: 2rem;
}
