.main {
  position: relative;
}
.App {
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  color: #555555;
}

.section-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: #555555;
  transition: all 0.2s;
  max-width: 1280px;
  padding: 1rem 1.5rem;
}
.section-container.opened-side-bar-screen {
  margin-left: 300px;
  width: 100%;
  transition: all 0.2s;
}
.section-container.closed-side-bar-screen {
  margin-left: 77px;
  width: 100%;
  transition: all 0.2s;
  max-width: 1480px;
}
.app-content {
  display: flex;
  width: 100%;
}

.Toastify__toast--success,
.Toastify__toast--error {
  min-height: 10px !important;
}
.Toastify__toast--success {
  background-color: var(--primary-color) !important;
  color: #fff;
}
.Toastify__toast--error {
  background-color: #ffdcd8 !important;
  color: #000;
}
.Toastify__close-button--success {
  color: #fff !important;
}
.Toastify__close-button--error {
  color: #000 !important;
}
.double-input {
  width: 49%;
}

.double-input-td {
  width: 100%;
}
.double-input-cd {
  width: 95%;
}

.add-domain-btn {
  color: #494949e8;
  cursor: pointer;
  margin-top: 10px;
}

.cmp-dmn-form {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.cmp-dmn-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-comp {
  margin-top: -1rem;
}
