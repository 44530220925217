.report-modal-title {
  padding: 0 15px;
}
.custom-button.download-btn {
  width: 7rem;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
}

.download-buttons-container {
  .disabled-button {
    background: var(--disabled-color) !important;
    border-color: var(--disabled-color);
    pointer-events: none;
  }
}
.details-modal-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.intermediate-download-link {
  font-size: 0.9rem;
}
.logs-main-container {
  padding: 0.6rem 0;
}
.logs-container {
  height: 68vh;
  overflow-y: hidden;
  font-family: 'Share Tech Mono', monospace;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  position: relative;
}

.logs-container:hover {
  overflow-y: auto;
}
.logs-outer {
  padding: 0.75rem 1rem;
}
.breadcrumb-container {
  background-color: #f8f9fb;
  padding-block: 0.75rem;
  padding-inline: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.breadcrumb-separator {
  margin-inline: 0.5rem;
}
.breadcrumb-container a,
.logs-breadcrumb-container a {
  text-decoration: none;
  color: #01a049;
}
.breadcrumb-container a:hover,
.logs-breadcrumb-container a:hover {
  text-decoration: none;
  color: #019443;
}

.switch-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mode-text {
  font-size: 0.85rem;
  color: rgb(180, 180, 180);
  margin-right: 0.75rem;
}

.switch-button-container button {
  height: 25px !important;
  > div > div {
    z-index: 130;
  }
}

.dark-mode {
  background-color: #000000;
  color: white;
  transition: all 0.2s ease-in;
}
.light-mode {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.2s ease-in;
}
.footer-buttons-container {
  display: flex;
  width: 100%;
}
.theme-icons {
  color: #fdb813 !important;
}

.disabled-intermediate-text {
  color: silver;
  cursor: not-allowed;
}
.job-details-card {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: relative;
}
.job-details-card-header {
  border-bottom: 1px solid #e5e5e5;
  padding: 1rem;
}
.job-details-card-body {
  height: 66vh;
  overflow-y: auto;
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
}
.job-details-card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
}

.download-buttons-container {
  display: flex;
  align-items: center;
}
.radio-button-container {
  margin-inline: 0.75rem;
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}
.radio-button-container > label {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}
.download-report-button {
  display: flex;
  width: 8.5rem !important;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 2rem;
}
.download-report-button img {
  width: 15px;
}

.details-value .ring-container .circle {
  margin-top: 2px;
}
.details-value .ringring {
  top: -2px;
}

.logs-container.dark-mode::-webkit-scrollbar-thumb {
  background: silver;
  border-radius: 10px;
}

.job-details-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-details {
  margin-bottom: 1.5rem;
}

.details-key {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: rgb(155, 152, 152);
  padding-bottom: 0.2rem;
}
.details-value {
  font-size: 1rem;
}
.pill-button .fa-download {
  margin-right: 0.5rem;
}
.capitalized-text {
  text-transform: capitalize;
}
.job-logs {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 1rem;
}
.logs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
  padding-left: 0.75rem;
}
.logs-header-status-container {
  display: flex;
  align-items: center;
}
.logs-header-status-container > span:first-child {
  margin-right: 0.7rem;
  color: rgb(155, 152, 152);
  font-size: 0.85rem;
}
.logs-header-status-container > span:last-child {
  margin-left: 0.5rem;
  font-size: 0.85rem;
  text-transform: capitalize;
}
.details-container {
  display: grid;
}
@media screen and (max-width: 700px) {
  .details-container {
    grid-template-columns: 100%;
  }
}
@media screen and (min-width: 701px) {
  .details-container {
    grid-template-columns: 50% 50%;
  }
}
@media screen and (min-width: 1200px) {
  .details-container {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }
}

.initcap-case {
  text-transform: capitalize;
}
.status-value {
  margin-left: 0.5rem;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.15rem;
  width: 17px;
  height: 17px;
  border: 1px solid var(--primary-color);
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 7px;
  height: 7px;
  background: var(--primary-color);
  position: absolute;
  top: 7px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
[type='radio']:disabled + label:before {
  border: 1px solid #adadad !important;
}
.breadcrumb-link {
  color: var(--primary-color);
}
