.side-bar-container {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  font-size: 0.85rem;
  padding: 1.25rem 0rem;
  transition: all 0.15s;
  user-select: none;
  background: #f8f8f8;
  opacity: 0.97;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.side-bar-uncollapsed {
  width: 300px;
}
.side-bar-collapsed {
  width: 77px;
}
.sidebar-links {
  text-decoration: none;
}
.active-path,
.non-active-path {
  color: #5a6474;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.25px;
}
.active-path {
  background: #fff;
  font-weight: 600;
}

.slide-in {
  animation: slidein 0.15s;
}
@keyframes slidein {
  from {
    transform: translate(-100%);
    opacity: 0;
  }
  to {
    transform: translate(0%);
    opacity: 1;
  }
}

.side-bar-header {
  width: 100%;
  padding-inline: 1rem;
  margin-bottom: 2rem;
  position: relative;
}
.side-bar-header > div.height {
  height: 49px;
}
.collapse-icon {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}

.welcome-back-text {
  color: #757d8a;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}
.user-name-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #5a6474;
}
.side-bar-title {
  font-weight: 600;
  margin-left: 1rem;
  font-size: 1.25rem;
}
.side-link {
  padding-left: 1rem;
  padding-block: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 0.5rem;
  margin-block: 1rem;
  &:hover:not(.active-path) {
    background-color: #fcfcfc;
  }
}

.side-link-icon-block {
  height: 2rem;
}
.side-link-icon,
.collapse-icon {
  width: 1.5rem;
  cursor: pointer;
}

.side-link-label {
  margin-left: 1rem;
  animation-name: font-show;
  animation-duration: 0.15s;
}

.big-icon {
  width: 1.7rem;
}
.show-side-bar-header .user-pic,
.hide-side-bar-header .user-pic {
  height: 49px;
  width: 49px;
  border-radius: 50%;
  cursor: pointer;
}
.show-side-bar-header .welcome-back-text,
.show-side-bar-header .user-name-text {
  transition: all 0.15s;
}

.hide-side-bar-header div {
  font-size: 0rem;
  transition: all 0.15s;
}
.logout-container {
  width: 100%;
  &.button {
    padding: 1rem;
  }
}

.hr-80 {
  width: 80%;
}
.hr-50 {
  width: 50%;
}

.side-bar-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user-settings-icon-container {
  display: grid;
  place-items: center;
}
.user-settings-icon {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 2px -2px rgba(27, 46, 94, 0.02);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
}

.collapsed-settings-icon {
  margin-top: 2rem;
}
.user-info {
  margin-left: 0.75rem;
}
.side-links-container {
  padding: 1rem;
}
