.error-message {
  font-size: 0.7rem;
  color: #e55c5c;
  margin-top: -0.75rem;
  margin-bottom: 0.25rem;
}

.error-hidden {
  visibility: hidden;
}
