.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -1rem -1.5rem 1rem;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ccc;
}
.user-name {
  color: var(--primary-color);
  font-weight: 600;
}
.credits-spinner {
  margin-right: 5rem;
}

.user-api-container {
  background-color: var(--primary-color);
  padding: 0.2rem 1.5rem;
  font-size: 0.85rem;
  margin-right: 1rem;
  border-radius: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.from-credits-update {
  animation-name: from-credits-update;
  animation-duration: 0.5s;
}
.to-credits-update {
  animation-name: to-credits-update;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.user-api-container.low {
  background-color: #ff4059;
}
.user-api-credits {
  color: #fff;
  letter-spacing: 1px;
  padding-top: 0.01rem;
}
