:root {
  --primary-color: #4e73f8;
  --primary-color-light: #6787fc;
  --primary-color-checkbox: #4e73f8;

  --primary-gradient: linear-gradient(
    180deg,
    var(--primary-color-light) 0%,
    var(--primary-color) 100%
  );

  --secondary-color: #686868;
  --secondary-color-shadow: #6868683f;

  --warning-color: #ea3c53;
  --warning-color-shadow: #d3354a;

  --disabled-color: #e0e0e0;
  --disabled-color-shadow: #c5c5c5;
}
