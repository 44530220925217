.empty-table {
  height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-table-text {
  font-weight: 300;
  font-size: 1.2rem;
  color: silver;
}
