@-webkit-keyframes inputHighlighter {
  from {
    background: var(--secondary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: var(--secondary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: var(--secondary-color);
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes from-credits-update {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes to-credits-update {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes font-show {
  from {
    font-size: 0;
  }
  to {
    font-size: 0.85rem;
  }
}
