.jobs-table {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  width: 100%;
  border: none;
  font-weight: 400;
}
.jobs-table th {
  padding: 1rem;
  vertical-align: bottom;
  font-family: inherit;
  font-weight: 700;
  font-size: 0.85rem;
  letter-spacing: 1px;
  background-color: #f6f9fc;
  text-transform: uppercase;
}
.jobs-table th:first-child {
  padding: 1rem 1.5rem 0.25rem 1.85rem;
}

.jobs-table th:first-child {
  vertical-align: initial;
}
.jobs-table td:first-child {
  vertical-align: middle;
}

.jobs-table td:not(:last-child) {
  padding: 0.5rem 1rem;
}
.jobs-table td:first-child {
  padding: 0.5rem 1.85rem;
}

.jobs-table td:last-child {
  text-align: right;
  padding: 0.5rem 1.5rem;
}
.jobs-table th:last-child {
  text-align: right;
  padding: 0.27rem 1.5rem;
}

.jobs-table th,
.jobs-table td {
  border: none;
}

.jobs-table th:first-child {
  width: 0.01%;
}
.jobs-table th:nth-child(2) {
  width: 15%;
}
.jobs-table th:nth-child(3) {
  width: 10%;
}
.jobs-table th:nth-child(4) {
  width: 8%;
}
.jobs-table th:nth-child(5) {
  width: 7%;
}
.jobs-table th:nth-child(6) {
  width: 9%;
}
.jobs-table th:nth-child(7) {
  width: 0.01%;
}
.jobs-table th:nth-child(8) {
  width: 5%;
}

.jobs-table td:nth-child(8) {
  text-align: right;
  padding: 0.5rem 1.5rem;
}
.jobs-table th:nth-child(8) {
  text-align: right;
  padding: 0 1.5rem;
}

.jobs-table th:nth-child(9) {
  width: 5%;
}
.jobs-table td {
  height: 4.5rem;
}

.jobs-table td {
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);
}

.jobs-table tr:nth-child(even) {
  background-color: #f8f9faab;
}

.checkmark-running {
  font-size: 1.2rem;
  vertical-align: middle;
  padding-right: 0.5rem;
  font-weight: 700;
  color: green;
}
.mark-stopped {
  font-size: 1.2rem;
  vertical-align: middle;
  padding-right: 0.5rem;
  font-weight: 700;
  color: red;
}

.job-property {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.jobs-type {
  text-transform: capitalize;
}

.job-property-title {
  font-weight: 500;
  text-transform: capitalize;
}

.job-property-value {
  vertical-align: middle;
}

.details-button {
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  padding: 0.2rem;
  width: 7rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
  border-radius: 100px;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: 0 3px var(--primary-color-shadow);
}

.details-button:hover {
  background-color: var(--primary-color-hover);
}
.details-button:active {
  background-color: var(--primary-color);
  box-shadow: 0 1px var(--primary-color-shadow);
  transform: translateY(2px);
}
.delete-icon,
.created-at-td {
  cursor: pointer;
  user-select: none;
  text-transform: lowercase;
}
.delete-icon {
  color: var(--warning-color);
}
.jobs-status {
  display: flex;
  text-transform: capitalize;
  align-items: center;
}
.jobs-status .ring-container {
  margin-right: 0.6rem;
}
.unarchive-icon {
  margin-bottom: 2px;
  cursor: pointer;
}

.modal-header,
.nav-link,
.details-modal-footer,
.switch,
.side-bar-container,
.header-nav,
.breadcrumb-item {
  z-index: 120;
}

.searchbar-container .heading-text {
  margin-bottom: 0;
}
.multiple-action {
  text-align: right;
}
.multiple-delete {
  text-align: right;
  color: var(--warning-color) !important;
}
.download-report-container {
  cursor: pointer;
}
.download-report-container span {
  margin-left: 0.3rem;
}
.w-0-5 {
  width: 0.5rem;
}
.add-icon,
.save-icon {
  cursor: pointer;
  color: var(--primary-color);
}
.minus-icon {
  cursor: pointer;
  color: var(--warning-color);
}

.competitor-domains-input .form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(3.15em + 0.1875rem) center;
}
.section-container .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}
.disabled {
  cursor: not-allowed;
}

.jobs-card {
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.action-card {
  padding: 8px 12px;
  background: #ffffff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-inline: 1rem 0px;
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.action-card.right {
  margin-inline: 0rem 1rem;
}
.action-card.right:hover {
  background-color: #ffffff;
}
.action-card:hover {
  background-color: rgb(250, 250, 250);
}
.invite-card {
  background: var(--primary-gradient);
  color: #fff;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}
.action-bar-icon {
  width: 1rem;
  margin-right: 0.5rem;
}
.invite-card .action-bar-icon {
  width: 1.3rem;
}

.ui.input > input {
  border: none;
  height: 1rem;
}
.ui.icon.input > input {
  padding-left: 0.25rem;
}
.disabled-action-card {
  background-color: #f3f3f3;
  pointer-events: none;
  user-select: none;
}
.jobs-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-btn {
  background: #fafdfb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  line-height: 1.5rem;
  font-size: 0.8rem;
  margin: 1rem;
}
.load-more-btn:hover {
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

.load-more-btn img {
  width: 15px;
  margin-right: 0.5rem;
}

.archived-user-btn-container {
  text-transform: capitalize;
}

.jobs-card-table.pending-invites th:nth-child(2) {
  width: 50%;
}
.jobs-card-table.pending-invites th:last-child {
  padding: 1rem 1.5rem;
}

.edit-credits-input input {
  width: 5rem;
}

.jobs-table.jobs-table-view th:first-child {
  width: 15%;
}
.jobs-table.jobs-table.jobs-table-view th:nth-child(2) {
  width: 10%;
}
.jobs-table.jobs-table.jobs-table-view th:nth-child(3) {
  width: 8%;
}
.jobs-table.jobs-table.jobs-table-view th:nth-child(4) {
  width: 7%;
}
.jobs-table.jobs-table.jobs-table-view th:nth-child(5) {
  width: 9%;
}
.jobs-table.jobs-table.jobs-table-view th:nth-child(6) {
  width: 0.01%;
}
.edit-credits-input input:focus {
  outline: 0;
}
.api-credits-input-error {
  border: 1.5px solidvar(--warning-color);
}
.user-table th:nth-child(1) {
  width: 0%;
}
.user-table th:nth-child(2),
.user-table th:nth-child(3) {
  width: 15%;
}

.user-table th:nth-child(4),
.user-table th:nth-child(5),
.user-table th:nth-child(6),
.user-table th:nth-child(7) {
  width: 12%;
}
.jobs-table.user-table td:nth-child(8) {
  width: 5%;
}
.user-table td:nth-child(2),
.user-table td:nth-child(3) {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
