#page-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
#login-page-loading {
  margin-top: 3rem;
}
#logsloading .three-balls {
  left: 42%;
}
.three-balls {
  margin: 0 auto;
  width: 10rem;
  text-align: center;
  position: absolute;
  left: 53.2%;
  top: 55%;
}
.pos-usnet {
  position: unset;
}

.three-balls .ball {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.3s infinite
    cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  animation: bouncedelay 1.3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
  margin: 0.25rem;
}

.three-balls .ball1 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.three-balls .ball2 {
  -webkit-animation-delay: -0.08s;
  animation-delay: -0.08s;
}

@keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: var(--primary-color);
  }
  25% {
    bottom: 40px;
    background-color: var(--secondary-color);
  }
  50% {
    bottom: 0;
    background-color: var(--secondary-color);
  }
  75% {
    bottom: 40px;
    background-color: var(--primary-color);
  }
  100% {
    bottom: 0;
    background-color: var(--primary-color);
  }
}

@-webkit-keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: var(--primary-color);
  }
  25% {
    bottom: 40px;
    background-color: var(--secondary-color);
  }
  50% {
    bottom: 0;
    background-color: var(--secondary-color);
  }
  75% {
    bottom: 40px;
    background-color: var(--primary-color);
  }
  100% {
    bottom: 0;
    background-color: var(--primary-color);
  }
}
.three-balls .small-balls {
  width: 10px;
  height: 10px;
}
