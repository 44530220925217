.floating-label {
  position: relative;
  margin-bottom: 20px;
}
.floating-input,
.floating-select {
  font-size: 14px;
  padding: 0;

  display: block;
  width: 100%;
  height: 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #757575;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  padding: 0;
}

.select-box-label,
.title-label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  top: 5px;
  text-transform: uppercase;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
  top: -18px;
  font-size: 11px;
  color: #999;
}

.select-nonempty-focus {
  top: -18px;
  font-size: 11px;
  color: #999;
  font-weight: 500;
}

.floating-input:focus ~ .bar:before,
.floating-input:focus ~ .bar:after,
.floating-select:focus ~ .bar:before,
.floating-select:focus ~ .bar:after {
  width: 50%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}

.highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.floating-input:focus ~ .highlight,
.floating-select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
