.delete-schedule-popover-buttons-container .confirm-btn,
.delete-schedule-popover-buttons-container .cancel-btn {
  cursor: pointer;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding: 0.375rem;
  width: 5rem;
  font-size: 0.8rem;
  border-radius: 0.25rem;
  margin: 0rem 0.3rem;
}
.delete-schedule-popover-buttons-container .confirm-btn {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: 0 3px var(--primary-color-shadow);
}

.delete-schedule-popover-buttons-container .confirm-btn:hover {
  color: var(--secondary-color) !important;
}
.delete-schedule-popover-buttons-container .cancel-btn {
  color: #fff;
  background-color: var(--warning-color);
  border-color: var(--warning-color);
  box-shadow: 0 3px var(--warning-color-shadow);
}

.popover-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.5rem;
}
.invite-popover,
.disable-alert-popover {
  width: 20rem;
  padding: 0.8rem;
  border: none !important;
  margin-top: 0.85rem !important;
  box-shadow: 1px 1px 4px 1px rgba(100, 100, 100, 0.2);
}

.delete-alert-message,
.disable-alert-popover {
  font-size: 0.7rem;
}
.alert-disable-warning {
  font-family: 'Work Sans', sans-serif;
  color: #8d8d8d;
  border-radius: 2px;
  background-color: #e0dbcd;
  border: 1px solid #ebe5d5;
  margin: 0 !important;
}
.alert-disable-warning span {
  padding-left: 0.3rem;
}

.alert-custom-warning {
  font-family: 'Work Sans', sans-serif;
  color: #b38600;
  border-radius: 2px;
  background-color: #fff2cf;
  border: 1px solid #ffedb7;
  padding: 0.5rem 0.9rem !important;
}

.popover-delete-icon {
  font-size: 1.1rem;
  vertical-align: middle;
}
.popover-delete-text {
  margin-left: 0.5rem;
  vertical-align: middle;
}
.delete-schedule-popover-buttons-container {
  display: flex;
  justify-content: center;
}

.delete-schedule-popeover-message {
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
}
.delete-schedule-popover-buttons-container .btn-custom-green,
.delete-schedule-popover-buttons-container .btn-custom-red {
  width: 5rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.invite-popover-head {
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  color: #8a8f8c;
}
.invite-popover-input {
  width: 100%;
  margin-block: 1rem;
  border: 1px solid #d0dfd7;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.25rem;
  padding-inline-start: 0.25rem;
}
.invite-popover-input:focus {
  border: 1px solid #d0dfd7;
  box-sizing: border-box;
  border-radius: 4px;
}
.invite-popover-button {
  background: var(--primary-gradient);
  color: #fff;
  cursor: pointer;
}
.invite-button {
  width: 100%;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  margin: 1rem;
  text-align: center;
  margin: 0 auto;
}
.disabled-invite-button {
  background-color: var(--disabled-color);
  color: rgb(93, 93, 93);
  cursor: not-allowed;
  user-select: none;
}
