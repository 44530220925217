.custom-toggle-button {
  margin-block: 0.5rem 0;
  display: flex;
  align-items: center;
  .label {
    margin-left: 0.5rem;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 11px;
    color: #596a84;
  }
}
