.select-box-visible {
  height: 2.5rem;
  border: 0.1px solid var(--secondary-color-shadow);
  box-shadow: 0 0 2px 0px var(--secondary-color-shadow);
  border-radius: 3px;
  font-size: 0.85rem;
  overflow: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-box-visible:focus {
  outline: none;
}
.select-box-visible option {
  display: inline-block;
  text-align: center;
  padding: 0.7rem 0;
  width: 10%;
}
.select-box-visible option:focus-visible {
  background-color: var(--secondary-color);
}
.select-box-visible option:not(:last-child) {
  border-right: 0.1px solid var(--secondary-color-shadow);
}
.processing-btn-container {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}
.processing-btn-container .CircularProgressbar-text {
  fill: var(--white) !important;
  font-size: 1.5rem !important;
}
.processing-btn-container .CircularProgressbar-trail {
  stroke: var(--white) !important;
}
.processing-btn-container .CircularProgressbar-path {
  stroke: silver !important;
}
.processing-circle-container {
  width: 20 !important;
  height: 20 !important;
  color: var(--white);
}
.progress-bar-container {
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
}
.progress-bar-container .progress {
  width: 12rem;
}
.progress-bar-container .progress-bar {
  background-color: #00acee;
}
.progress-text {
  font-size: 0.6rem;
  margin-left: 0.5rem;
}
.radio-box-container {
  border: 0.1px solid var(--secondary-color-shadow);
  box-shadow: 0 0 2px 0px var(--secondary-color-shadow);
}
.radio-box-container .radio-label:not(:last-child) {
  border-right: 0.1px solid var(--secondary-color-shadow);
}
.radio-label {
  display: inline-block;
  width: 10%;
  margin: 0;
}
.radio-label:hover {
  background-color: rgb(250, 250, 250);
}
.checkbox-number {
  display: block;
  font-size: 0.85rem;
  cursor: pointer;
  text-align: center;
  padding: 0.7rem;
  color: var(--secondary-color);
}
.grouping-radio-box {
  display: none;
}
.grouping-radio-box:checked + .checkbox-number {
  background-color: var(--primary-color-checkbox);
  color: #fff;
  font-weight: 700;
}
.upload-progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 130;
  backdrop-filter: blur(0.7px);
  transition: all 1s ease-in;
}
.progress,
.progress-text {
  z-index: 140;
}

.select-box-container .MuiFormLabel-root.Mui-focused,
.select-box-container .MuiInput-underline .Mui-focused {
  color: var(--secondary-color);
}
.title-container .MuiFormLabel-root.Mui-focused,
.title-container .MuiInput-underline .Mui-focused {
  color: var(--secondary-color);
}

.file-progress-bar-container {
  cursor: progress;
}

.target-domain-checkbox {
  color: #000000;
}

.title-grouper {
  padding-bottom: 0;
}
