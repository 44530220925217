.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.title-container {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.location-select {
  width: 100%;
  max-width: 100%;
}

.dropdown > div {
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.title-container .title-input,
.floating-select {
  font-size: 14px;
  padding: 4px 4px;
  display: block;
  width: 100%;
  height: 30px;
  color: #999;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #757575;
}

.search-engine-container {
  margin-bottom: 1.5rem;
}

.select-box-sub-container {
  display: flex;
  flex-direction: column;
  width: 49%;
  // padding-bottom: 0.5rem;

  .select-box-label-visible {
    margin-top: 1rem;
  }
}
.select-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.select-box-label-visible {
  display: block;
}

.file-upload-div {
  padding-bottom: 0.5rem;
}

.file-upload-text {
  width: 20%;
  display: block;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.file-upload-container {
  border-radius: 3px;
  box-shadow: 0 0 2px 0px var(--primary-color-shadow);
  font-size: 0.85rem;
  background-position: left bottom;
  position: relative;
}
.custom-file-input {
  opacity: 1 !important;
  font-size: 0.85rem;
  line-height: 1;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  display: none;
}
.custom-file-input::before {
  content: 'Upload the file';
  display: inline-block;
  border: 0.5px solid #b7b7b7;
  padding: 7px 10px;
  outline: none;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-size: 10pt;
  margin-right: 1rem;
  border-radius: 3px;
  opacity: 1;
}

.my-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.my-file-input + label {
  font-size: 0.85rem;
  color: var(--secondary-color);
  padding: 4px 10px;
  border-radius: 3px;
  border: 1px solid #b7b7b7;
  display: inline-block;
  cursor: pointer;
}

.file-chosen {
  padding-left: 1rem;
  max-width: 10rem;
  margin-bottom: 8px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-upload-button {
  display: flex;
  align-items: center;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #757575;
  padding: 0px;
}
.ignore-special-chars-text {
  font-size: 0.9rem;
  display: inline-block;
  margin-left: 1.5rem !important;
}
.change-target-domain-text {
  font-size: 0.9rem;
  display: inline-block;
  color: #999999;
  margin-left: 1.5rem;
}
.change-target-domain-container {
  display: flex;
  align-items: center;
  margin-block: 0.15rem 0.75rem;
}
.ignore-special-chars-checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.cmp-domain-warning {
  color: #d8000c;
  font-size: 0.85rem;
}
.cmp-domain-card {
  margin: 0.5rem;
  margin-left: 0;
  padding: 0.25rem;
  width: 95%;
}
.main-description {
  div:first-child {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 3rem;
    letter-spacing: -0.02em;
    color: #404d61;
  }
  div:nth-child(2) {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.15rem;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    color: #757d8a;
  }
}

.red-border {
  border: 1px solid #d8000c;
  border-radius: 5px;
}
