.ring-container {
  position: relative;
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0;
}
.green-dot {
  background-color: #28cc2d;
}
.red-dot {
  background-color: #ea3c53;
}
.blue-dot {
  background-color: #ffb900;
}
.grey-dot {
  background-color: #5f5f5f;
}
.ringring {
  border: 3px solid #ffb900;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  left: -3.95px;
  top: -3.98px;
  animation: pulsate 0.8s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: pulsate 0.8s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
