.file-upload-container {
  .file-dropper {
    display: flex;
    align-items: flex-end;

    .DropZone {
      width: 20%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23404D61FF' stroke-width='3' stroke-dasharray='10%2c 8%2c 9' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 4px;
      border-radius: 4px;
      border-radius: 4px;
      border: none;
      border-radius: 4px;
      text-align: center;
      font-weight: bold;
      padding: 2em !important;
      cursor: pointer;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .DropZone--over {
      background-color: #4e73f812;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%233C64F4FF' stroke-width='3' stroke-dasharray='10%2c 8%2c 9' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 4px;
      img {
        filter: invert(41%) sepia(66%) saturate(3743%) hue-rotate(216deg)
          brightness(102%) contrast(94%);
      }
    }
    .selected-file-name {
      font-size: 1rem;
      margin-left: 1rem;
    }
  }
}
