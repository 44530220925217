.console-dropdown {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionSummary-root {
  padding: 0 !important;
  min-height: unset !important;
  align-items: center;
}
.MuiAccordionSummary-content {
  margin: 0 !important;
}
.MuiAccordion-root:before {
  height: 0 !important;
}
.MuiAccordionSummary-expandIcon {
  position: absolute;
  right: 0;
}
.dropdown-icon {
  position: absolute;
  right: 1rem;
}
.console-dropdown {
  .dropdown-items {
    display: block;
    padding-top: 0;
    padding-inline: 0;
  }
}

.non-selected-screen {
  color: rgb(155, 155, 155);
}
.selected-screen {
  > div {
    border-radius: 0.5rem;
    font-weight: 600;
  }
}

.dropdown-links {
  padding-block: 0.25rem;
  > div {
    padding-left: 2.5rem;
    padding-block: 0.25rem;
    cursor: pointer;
    animation-name: font-show;
    animation-duration: 0.15s;
  }
}
