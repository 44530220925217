.tag-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 7px 4px 9px;
  background: var(--primary-color);
  border-radius: 21px;
  margin: 0.15rem;
  cursor: pointer;
  user-select: none;
  border: 0.6px solid var(--primary-color);
  color: #fff;
}
.editing-domain {
  background: var(--primary-color-light);
  border: 0.6px dashed #233029;
}
.tag-container img {
  margin-inline: 0.35rem;
}
.hold-domain-icon {
  width: 8px;
}
.cross-domain-icon {
  width: 12px;
}
.tag-container .edit-domain-input {
  margin-bottom: 0;
}
.edit-domain-input {
  width: fit-content;
}
.edit-domain-input {
  .form-control {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    height: fit-content;
    color: #fff !important;
    &:focus {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      height: fit-content;
    }
  }
}
