.input-select-container {
  width: 49%;
  margin-block: 0.5rem;
}
.input-select-container label {
  margin-bottom: 0.25rem;
}
.input-select {
  width: 100%;
}
.input-select .dropdown-menu {
  width: 100%;
}
.input-select .dropdown-toggle {
  height: calc(1.5em + 0.75rem + 2px);
}

.input-select .btn-primary {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 4px;
  color: #53655b;
  padding: 0.375rem 0.75rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-select .btn-primary:hover {
  background: #ffffff;
  color: #53655b;
}
.btn-primary:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus {
  background: #ffffff !important;
  color: #53655b !important;
  border: none !important;
}
.input-select .input-selected-item {
  background-color: #e0e3e6 !important;
}
