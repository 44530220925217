.archived-users-modal .modal-dialog {
  max-width: 80%;
}

.empty-table-class {
  font-weight: 300;
  font-size: 1.2rem;
  color: silver;
  padding: 1rem;
}

.modal-body.pt-half {
  padding-top: 0.5rem;
}
