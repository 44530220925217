.heading-text {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 40px;
  color: #404d61;
  letter-spacing: 0.2px;
}

.sub-heading-text {
  text-transform: capitalize;
  font-size: 1.5rem;
  letter-spacing: -0.02em;
  line-height: 3rem;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.button-container {
  margin-top: 1.5rem;
}
.custom-button {
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding: 0.375rem;
  width: 12rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  color: #fff;
  background: var(--primary-gradient);
  border-radius: 8px;
}

.loader-button {
  background-color: #d8d8d8;
  border-color: #d8d8d8;
  box-shadow: 0 3px #cfcccc;
  color: white;
  cursor: progress;
}
.loader-button:hover {
  background-color: #d8d8d8;
}
.processing-text {
  margin-left: 0.7rem;
  pointer-events: none;
}
.error-label {
  color: #d8000c !important;
}
.error-input {
  border-bottom: 1px solid #d8000c !important;
}

.custom-checkbox-container {
  width: fit-content;
  position: relative;
  padding-left: 5px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid #ccc;
}

.custom-checkbox-container:hover input ~ .custom-checkmark {
  background-color: #fff;
}

.custom-checkbox-container input:checked ~ .custom-checkmark {
  background-color: var(--primary-color);
  border: 1px solid transparent;
}

.custom-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox-container input:checked ~ .custom-checkmark:after {
  display: block;
}

.custom-checkbox-container .custom-checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.title-container .MuiInput-underline:hover:not(.Mui-disabled):before,
.title-container .MuiInput-underline:after,
.select-box-container .MuiInput-underline:hover:not(.Mui-disabled):before,
.select-box-container .MuiInput-underline:after {
  border-bottom: 1px solid var(--secondary-color);
}

.select-box-container .MuiSelect-select:focus {
  background-color: rgba(0, 0, 0, 0.01);
}
.domains-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-title-input {
  width: 49%;
}
.report-title-input-noc {
  width: 49%;
}
.no-clusters-input {
  width: 14%;
}
.empty-container {
  width: 33%;
}
.target-domain-input,
.competitor-domains-input {
  width: 49%;
}
body .form-control {
  border: none;
  transition: none;
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}
.add-icon-container {
  position: absolute;
  border-left: 0.5px solid #ced4da;
  right: 0;
  top: 0;
  margin-top: 2.275rem;
}
.add-icon-container img {
  margin-inline: 0.5rem;
  margin-top: 0.25rem;
}
.manage-icon-container {
  position: absolute;
  top: 2.5rem;
  &.disabled-icon {
    top: 2.575rem;
  }
}

.manage-icon-container img {
  margin-inline: 0.5rem;
  width: 25px;
}
.input-selects-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.w-49 {
  width: 49%;
}

.add-domain-icon {
  cursor: pointer;
}
.competitor-card {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.tags-card {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  padding: 0.5rem;
  border-bottom: 0.1px solid #f1f1f1;
}
.tags-card-footer {
  padding: 0.5rem;
}
.footer-close-button {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.25rem 1.85rem;
  cursor: pointer;
  display: inline-block;
}

.custom-checkbox-container.text {
  padding-left: 1.75rem;
  margin-bottom: 0px;
  font-size: inherit;
}

.custom-checkbox-container.text .custom-checkmark {
  top: 1px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
.disabled-input-text {
  background-color: #eef1f5 !important;
}
.disabled-input-text,
.competitor-domains-input .manage-domains-input.disabled-input-text {
  cursor: not-allowed;
}
