@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

.login-container .login-card-header {
  background: #eef1f5;
  border-radius: 10px 10px 0px 0px !important;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.login-card-header:first-child {
  font-size: 1.2rem;
}

.input-container:nth-child(2) {
  margin: 1rem 0 2rem;
}
.input-container:last-child {
  margin: 2rem 0 2rem;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    116.06deg,
    #69d1fe 1.04%,
    #5583f9 45.95%,
    #9788f4 108.82%
  );
  font-family: 'DM Sans', sans-serif;
}
.login-container .login-card {
  width: 32%;
  border: 0;
  max-width: 500px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  border: 0.6px solid #c5ced7;
  box-sizing: border-box;
  backdrop-filter: blur(30px);
}
.login-container .login-card-footer {
  display: flex;
  justify-content: center;
  border: 0;
  background: unset;
}
.signin-btn-container {
  display: flex;
  justify-content: center;
  padding-block: 3px;
}
.signin-button {
  padding: 0.35rem 1rem;
  color: var(--primary-color);
  width: 7rem;
  background: #fff;
  border: 0.4px solid #c5ced7;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  letter-spacing: 1.2px;
  padding: 0.375rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
}

.login-card-body {
  padding: 1.25rem 2.75rem !important;
}

.login-card-body .invalid-alert {
  padding: 0.575rem 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.invalid-alert-message {
  margin-left: 1rem;
}
.login-spinner {
  color: #3582d8;
}
.logo-shape {
  position: absolute;
  height: 100vh;
}
.verification-screen {
  background-color: #f9fdfb;
  height: 100vh;
  display: grid;
  place-items: center;
}
