.logout-modal-footer .close-btn {
  width: 7rem;
  padding: 0.35rem;
  background: transparent;
  color: #383838;
  border-color: #c0bfbf;
}
.logout-modal-body {
  font-size: 1.125rem;
  padding-block: 3rem !important;
  padding-inline: 2rem !important;
}
.logout-modal-footer .confirm-logout-btn {
  width: 7rem;
  margin-right: 1rem;
}
