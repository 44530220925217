.pos-rel {
  position: relative !important;
}
.competitor-domains-input .manage-domains-input {
  padding-left: 3rem;
  cursor: pointer;
  caret-color: transparent;
}
.input-text {
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.input-text:focus {
  border: 1px solid #267abe;
}
.input-text:focus:hover {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.input-text {
  &.error-input-text {
    border: 1px solid #f43c3c;
    box-shadow: 0px 0px 4px rgb(255 89 89 / 15%);
  }
  &.error-input-text:focus {
    border: 1px solid #f43c3c;
  }
}
.disabled-icon img {
  color: #e9e9e9 !important;
}

.input-text-label,
.input-select-label {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  color: #636e7e;
}
.show-password-icon {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}
